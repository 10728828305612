import { Trans } from "@lingui/react/macro"
import { Text } from "@mantine/core"
import { ContactUs } from "~/app/interface/ContactUs"
import { PageWrapper } from "./_components"

export const Page = ({ currentPath }: { currentPath: string }) => (
  <PageWrapper currentPath={currentPath}>
    <PageContents />
  </PageWrapper>
)

export const PageContents = () => (
  <Trans>
    <Text fz="4rem" fw={500}>
      404
    </Text>
    <Text mb="md">We couldn't find what you're looking for.</Text>
    <Text mb="md">
      Need help finding something?{" "}
      <ContactUs method="email" subject="404">
        Send us an email.
      </ContactUs>
    </Text>
  </Trans>
)
